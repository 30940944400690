<template>
  <v-container id="data-tables" tag="section">
    <base-material-card class="px-5 py-3" color="primary" icon="mdi-watermark" inline>
      <v-spacer></v-spacer>

      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn class="ma-2" color="primary" elevation="2" small @click.stop="dialog = true">
          Nueva Marca
        </v-btn>
      </template>

      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Empieza texfield para la barra de busqueda -->
      <v-text-field
        append-icon="mdi-magnify"
        class="ml-auto"
        hide-details
        label="Búsqueda"
        single-line
        style="max-width: 250px"
        v-model="search"
      />
      <!-- Termina texfield para la barra de busqueda -->

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog v-model="isSure" @click="cancel" />

      <!-- Empieza Dialogo Agregar/Editar Marca de vehiculo -->
      <v-dialog max-width="500px" v-model="dialog">
        <v-card>
          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-container>
              <v-row>
                <!-- Marca -->
                <v-col cols="12">
                  <v-text-field
                    label="Marca*"
                    required
                    v-model="editedItem.marca"
                    :error-messages="marcaErrors"
                    @blur="$v.editedItem.marca.$touch()"
                    @change="onFormChangeHandler"
                    @input="$v.editedItem.marca.$reset()"
                  ></v-text-field>
                </v-col>

                <!-- Descripcion -->
                <v-col cols="12">
                  <v-text-field
                    label="Descripción"
                    v-model="editedItem.descripcion"
                    @change="onFormChangeHandler"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y Checklist -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox label="Crear otro" v-model="checkbox" :disabled="isEditing"></v-checkbox>
            <v-btn color="error" text @click="checkFormChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Termina Dialogo Agregar/Editar Marca de vehiculo -->

      <v-divider class="mt-3" />

      <!-- Data table de Marcas de vehiculos -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        :headers="headers"
        :items="marcas"
        :loading="isLoading"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <!-- Slot del boton activo -->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>
        <template v-slot:no-data>¡No hay datos para mostrar!</template>
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="marcas" :dataFields="fields" fileName="MarcasVehiculo" />
    </base-material-card>
  </v-container>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'

export default {
  components: {
    ConfirmationDialog,
    DataExporter,
    Status,
  },

  data: () => ({
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    overlay: false,
    search: undefined,
    headers: [
      { text: 'Marca', align: 'start', sortable: false, value: 'marca' },
      { text: 'Descripción', value: 'descripcion' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],

    marcas: [],
    value: [],
    defaultItem: {
      marca: '',
      descripcion: '',
      activo: true,
    },
    editedItem: {
      marca: '',
      descripcion: '',
      activo: true,
    },
    fields: {
      Marca: 'marca',
      Descripcion: 'descripcion',
      Activo: 'activo',
    },
  }),

  mixins: [validationMixin],
  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      marca: { required, minLength: minLength(3), maxLength: maxLength(20) },
    },
  },

  computed: {
    //Titulo de los formularios
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva Marca' : 'Editar Marca'
    },

    marcaErrors() {
      const errors = []
      if (!this.$v.editedItem.marca.$dirty) return errors
      !this.$v.editedItem.marca.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.marca.minLength &&
        errors.push('Este campo debe de tener un mínimo de 3 caracteres.')
      !this.$v.editedItem.marca.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.obtenerMarcas()
  },

  methods: {
    /**
     * @description confirma la cancelacion de la edicion en el formulario de mantenimiento
     * de marcas
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Revisa si hay cambios en el formulario de mantenimiento de
     * marcas
     */
    checkFormChanges() {
      if (this.changed === true) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el diálogo de mantenimiento de marcas
     */
    close(checkbox) {
      this.isSure = false
      this.checkbox = checkbox
      if (!this.checkbox) {
        this.dialog = false
        this.isEditing = false
      }

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description elimina un registro de la tabla y de la base de datos
     */
    deleteItem(item) {
      const index = this.marcas.indexOf(item)
      confirm('Esta seguro que desea eliminar este registro?') && this.grupos.splice(index, 1)
    },

    /**
     * @description prepara un registro para su edición y muestra el dialogo
     * para editar marcas
     */
    editItem(item) {
      this.isEditing = true
      this.editedIndex = this.marcas.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    /**
     * @description Obtiene un listado de todas las marcas
     */
    async obtenerMarcas() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('brandVehicle/fetchBrandsVehicle', payload)
        .then((response) => {
          this.marcas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Marca que ha cambiado el valor de un campo en el formulario
     */
    onFormChangeHandler() {
      this.changed = true
    },

    /**
     * @description Guarda el contenido del formulario ya sea para editar una marca o
     * crear una nueva
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          marca: this.editedItem.marca,
          descripcion: this.editedItem.descripcion,
        }
        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('brandVehicle/editBrandVehicle', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizó con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
          Object.assign(this.marcas[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('brandVehicle/addBrandVehicle', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.marcas.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.msg,
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },
  },
}
</script>
